export default defineComponent({
  props: {
    impact: {
      type: Object,
      required: true
    },
    firstRow: {
      type: Array,
      required: true
    },
    secondRow: {
      type: Array,
      required: true
    },
    individualRows: {
      type: Array,
      required: true
    }
  }
});